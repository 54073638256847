<template>
  <div class="layout-main bg-white">
    <section class="main terms">
      <div class="container">
        <h1 class="title fs-h4" v-text="title"></h1>
        <div class="text-block">
          百岳路線：由台灣登山界四大天王之一的林文安規劃發起，並與台灣登山界耆老探勘及商議後選定標高三千公尺以上的山岳共 100 座。
        </div>
        <div class="text-block">
          小百岳 (資料庫建置中)
        </div>
        <div class="text-block">
          郊山與其他 (資料庫建置中)
        </div>
        <div>
          縱走路線 (資料庫建置中)
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'DatabaseRange',
  data() {
    return {
      searchText: '',
      title: '資料庫範圍',
    };
  },
  props: {
  },
  components: {
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    }
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    siderbarToggle: function() {
      this.$attrs('.sidebar').toggleClass('active')
    },
    searchClicked: function(event) {
      if(event.isComposing) {
        return
      }
      if(this.searchText.length === 0) {
        alert('請至少輸入一個字');
      } 
    // window.location.href = '/search_result?query=' + this.searchText;  
    },
  }
}
</script>


<style lang="scss" scoped>
  @import "src/assets/scss/terms-dbUsage.scss";
</style>
